import './linkInText.css'
import React from 'react'
import qs from 'qs'

export function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g
  const convertContent = text.replace(urlRegex, function (url) {
    return `<a style="color: -webkit-link" href="${url}" target="_blank">${url}</a>`;
  })

  const htmlArr = [];
  convertContent.split('\n').forEach(function (text) {
    const textHtml = `<p>${text}</p>`;
    htmlArr.push(textHtml)
  })

  return {__html: htmlArr.join("")};
}

const LinkInText = ({ text }) => {
  return (
    <div
      className="link-in-text-wrapper"
      dangerouslySetInnerHTML={
        urlify(text)
      }
    />
  )
}

export default LinkInText
