import { Network } from '.'

export default class RewardNetwork {
  constructor(network: Network) {
    this.network = network
  }

  // 음감회 리스트 조회
  async getReward(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/reward`,
        'get',
        params
      )
      return data.data || null
    }
    catch (error) {
      console.warn('RewardNetwork getReward error', error)
    }
  }
}
