import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { Typography, Flex } from '../../atoms'
import { ArtistTrackItem } from '../../molecules'

const ArtistSongList = ({
  list,
  sortBy,
  setSortBy,
  offset,
  setOffset,
  maxCount,
}) => {
  const countOffset = () => {
    setOffset(offset + 1)
  }

  return (
    <Flex type="column">
      <div
        style={
          {
            alignSelf: 'flex-end',
            margin: '-20px 0 20px 0',
          }
        }
      >
        <Typography
          type="Medium"
          size="12px"
          color={sortBy === 'albumInfo.releasedAt' ? '#242424' : '#c9c9c9'}
          style={{ paddingRight: '10px', cursor: 'pointer' }}
          onClick={() => setSortBy('albumInfo.releasedAt')}
        >
          최신순
        </Typography>
        <Typography
          type="Medium"
          size="12px"
          color={sortBy === 'likeCount' ? '#242424' : '#c9c9c9'}
          style={{ paddingRight: '10px', cursor: 'pointer' }}
          onClick={() => setSortBy('likeCount')}
        >
          인기순
        </Typography>
        <Typography
          type="Medium"
          size="12px"
          color={sortBy === 'title' ? '#242424' : '#c9c9c9'}
          style={{ cursor: 'pointer' }}
          onClick={() => setSortBy('title')}
        >
          이름순
        </Typography>
      </div>
      <InfiniteScroll
        dataLength={list.length}
        scrollThreshold={0.8}
        next={countOffset}
        hasMore={maxCount > list.length}
        loader={
          <ReactLoading
            type="spin"
            color="black"
            style={
              {
                width: '50px',
                height: '50px',
                margin: '0 auto 50px auto',
              }
            }
          />
        }
        // className="scroll_div"
      >
        {
          list
          && list.map((item, index) => (
            <ArtistTrackItem item={item} index={index} key={index} />
          ))
        }
      </InfiniteScroll>
    </Flex>
  )
}

export default ArtistSongList
