import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet'
import { AppDownloadTemplate } from '@components/templates'
import { download_preview_img } from '@images/index'

const AppDownloadPage = () => {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>{'플램 - 리워드 음감회, PLAM'}</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="플램 - 리워드 음감회, PLAM" />
        <meta property="og:site_name" content="플램 - 리워드 음감회, PLAM" />
        <meta property="og:description" content="음악을 감상하면 돈이 벌리는 음악 앱테크 리워드 서비스, 리워드 음감회로 여러분을 초대합니다. 지금 다운로드 받고, 새로운 음악을 발견하며 돈도 벌어보세요." />
        <meta property="og:image" content="https://plam.in/share-referral-code.png" />
        <meta property="fb:app_id" content="705435816889569" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="플램 - 리워드 음감회, PLAM" />
        <meta property="twitter:description" content="음악을 감상하면 돈이 벌리는 음악 앱테크 리워드 서비스, 리워드 음감회로 여러분을 초대합니다. 지금 다운로드 받고, 새로운 음악을 발견하며 돈도 벌어보세요." />
        <meta property="twitter:image" content="https://plam.in/share-referral-code.png" />
      </Helmet>
      <AppDownloadTemplate />
    </>
  )
}

export default AppDownloadPage
