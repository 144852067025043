export const IS_DEV =
  process.env.NODE_ENV === 'dev' || process.env.NODE_ENV === 'development'

export const API_VERSION = 'v1'

export const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL
export const DOMAIN_URL_LENGTH = process.env.REACT_APP_DOMAIN_URL_LENGTH
export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL
export const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const ANDROID_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.naivy.plam'
export const IOS_STORE_URL =
  'https://apps.apple.com/us/app/%ED%94%8C%EB%9E%A8-plam-in/id6446476110'

export const COMMENT_REPORT_REASON = [
  { value: '영리 목적/홍보성', label: '영리 목적/홍보성' },
  { value: '음란성/선정성', label: '음란성/선정성' },
  { value: '욕설/인신공격', label: '욕설/인신공격' },
  { value: '개인정보노출', label: '개인정보노출' },
  { value: '같은 내용 반복 게시 (도배)', label: '같은 내용 반복 게시 (도배)' },
  { value: '기타', label: '기타' },
]

export const ItemTypes = {
  FILEITEM: 'fileItem',
}
