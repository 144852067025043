import React, { useEffect, useState } from 'react'
import { Flex } from '@components/atoms'
import styled from 'styled-components'
import { observer } from 'mobx-react'

const ArtistSmartLinkItem = ({ link, onClick }) => {
  const [previewImage, setPreviewImage] = useState('')

  useEffect(() => {
    const getPreviewImage = async () => {
      if (link?.linkUrl) {
        const response = await fetch(
          `https://rlp-proxy.plam.kr/v2?url=${encodeURIComponent(link?.linkUrl)}`,
        )
        const json = await response.json()
        if (json?.metadata?.image) {
          setPreviewImage(json?.metadata?.image)
        }
      }
    }

    getPreviewImage()
  }, [])

  return (
    <LinkItem
      onClick={() => {
        onClick(link?.linkUrl)
      }}
    >
      {previewImage && <img src={previewImage} alt="preview" style={{ objectFit: 'cover' }} />}
      <div>{link?.title}</div>
    </LinkItem>
  )
}

export default observer(ArtistSmartLinkItem)

const LinkItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #f4f4f4;

  font-size: 14px;
  font-weight: 500;

  img {
    width: 44px;
    height: 44px;
    border-radius: 4px;
  }

  div {
    flex: 1;
    text-align: center;
  }
`
