import { Flex, H4, Caption1 } from '@components/atoms'
import { recycle_img_gray, move_btn_img } from '@images/'
import React from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import { Observer } from 'mobx-react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const HomeInPlamList = ({ fetchlatestActicleList, latestActicleList }) => {
  const history = useHistory()
  const { handlerObj } = useStore()

  return (
    <Flex type="column">
      <Flex justify="space-between" align="center">
        <Flex
          align="center"
          style={{ cursor: 'pointer', height: '29px' }}
          onClick={
            () =>
              history.push('/inplam/total?offset=0&sortBy=createdAt')
          }
        >
          <H4 type="Bold" align="left">
            IN PLAM
          </H4>
          <img
            src={move_btn_img}
            style={{ width: '20px', height: '20px' }}
            alt="move_icon"
          />
        </Flex>
        <Flex align="center" style={{ cursor: 'pointer' }}>
          <img
            src={recycle_img_gray}
            alt="refresh_icon"
            style={{ marginRight: 4, width: 20, height: 20 }}
          />
          <Caption1
            type="Regular"
            color={colors.brownish_grey}
            onClick={fetchlatestActicleList}
          >
            새로고침
          </Caption1>
        </Flex>
      </Flex>
      <InPlamList>
        <Observer>
          {
            () => {
              if (handlerObj?.['fetchlatestActicleList']?.isLoading) {
                return Array(5)
                  .fill(1)
                  .map((item, index) => (
                    <InPlamListItem
                      key={`skel-${index}`}
                      style={
                        {
                          lineHeight: 1,
                          paddingTop: '8px',
                          paddingBottom: '8px',
                        }
                      }
                    >
                      {
                        <ArtistText>
                          <Skeleton width={60} height={18} />
                        </ArtistText>
                      }
                      <TitleText>
                        <Skeleton width={'100%'} height={18} />
                      </TitleText>
                    </InPlamListItem>
                  ))
              }

              if (latestActicleList && latestActicleList.length) {
                return latestActicleList.map(item => (
                  <InPlamListItem key={item._id}>
                    {
                      item.isNotice ? (
                        <ArtistText
                          style={
                            {
                              color: '#ea4653',
                              border: 'solid 1px #ea4653',
                              cursor: 'pointer',
                              borderRadius: 2,
                              fontFamily: 'NotoSansCJKkr-Medium',
                              padding: '2px 0',
                            }
                          }
                          onClick={
                            () =>
                              history.push(
                                `/inplam/total/${item._id}?offset=0&sortBy=createdAt`,
                              )
                          }
                        >
                      공지사항
                        </ArtistText>
                      ) : (
                        <ArtistText
                          onClick={
                            () =>
                              history.push(
                                `/artist/${item.uniqueName}`,
                                // `/inplam/${item.uniqueName}?offset=0&sortBy=createdAt`,
                              )
                          }
                        >
                          {item.artistName}
                        </ArtistText>
                      )
                    }
                    <TitleText
                      onClick={
                        () => {
                          if (item.isNotice) {
                            history.push(
                              `/inplam/total/${item._id}?offset=0&sortBy=createdAt`,
                            )
                          }
                          else {
                            history.push(
                              `/inplam/${item.uniqueName}/${item._id}?offset=0&sortBy=createdAt`,
                            )
                          }
                        }
                      }
                    >
                      {item.title}
                    </TitleText>
                  </InPlamListItem>
                ))
              }
              return <Flex>인기 게시글이 없습니다</Flex>
            }
          }
        </Observer>
      </InPlamList>
    </Flex>
  )
}

export default HomeInPlamList

const InPlamList = styled(Flex)`
  flex-direction: column;
  height: fit-content;
  max-height: 170px;
  margin: 16px 0px;
`

const InPlamListItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  height: 34px;
  border-bottom: solid 1px ${colors.whitef4};
  align-items: center;
  box-sizing: border-box;
`

const ArtistText = styled(Caption1)`
  min-width: 107px;
  max-width: 107px;
  font-family: NotoSansCJKkr-Bold;
  /* text-align: left; */
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`

const TitleText = styled(Caption1)`
  width: 100%;
  font-family: NotoSansCJKkr-Regular;
  text-align: left;
  margin-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`
